<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="profile-switch" slot="component">
      <p v-if="!isSetNewPin" class="profile-switch-pin-title">{{ $t("Please Verify before you create Profile") }}</p>
      <p v-if="isSetNewPin" class="profile-switch-pin-title">
        {{ $t("Please Setup a profile pin for this profile to proceed") }}
      </p>

      <div v-if="isSetNewPin" class="profile-details">
        <h3 class="profile-details-title">{{$t("Profile")}}</h3>

        <p class="profile-details-name">{{ profile.profilename }}</p>
      </div>

      <div class="profile-switch-pin">
        <form @submit.prevent="submitVerifyOrSetProfile">
          <div class="form-group">
            <div class="error-container" v-if="formError">
              <p class="error">{{ formError }}</p>
            </div>
            <div class="form-control profile-switch-pin-box">
              <label>{{$t("Enter PIN")}}</label>
              <div dir="ltr" id="pininputs" class="profile-switch-pin-box-inputs">
                <input
                  v-for="(item, index) in numberOfOtpBlocks"
                  :key="index"
                  class="pin"
                  type="text"
                  v-model="userPin[item - 1]"
                  @input="grabPin"
                  maxlength="1"
                  inputmode="numeric"
                />
              </div>
            </div>

            <!-- Date of Birth input Box for Profile Pin Setup For Master Profile -->
            <div v-if="isSetNewPin" class="profile-details-dob">
              <h3 class="profile-details-title">{{ $t("Please Enter Date to birth for your main profile") }}</h3>

              <input
                type="text"
                v-model="dateOfBirth"
                id="addProfileDatePicker"
                :placeholder="`${datePlaceholder}`"
                onfocus="(this.type='date')"
                class="profileDate"
                :max="maxDateLimit"
              />
            </div>
          </div>
          <div>
            <button
              class="button-primary"
              :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
            >
              {{$t("Submit")}}
            </button>
          </div>
        </form>
      </div>

      <br />

      <div v-if="isLoading"><Loading /></div>
    </div>
  </Popup>
</template>

<script>
import Utility from "@/mixins/Utility.js";
import { _providerId } from "@/provider-config.js";
import { mapActions, mapMutations } from "vuex";

import snackbar from "node-snackbar";
import "../../../node_modules/node-snackbar/dist/snackbar.css";
import { showSnackBar } from '../../utilities';
import jwt_decode from "jwt-decode";
import { getFreshTokenUpdate, actSaveExpireTime } from '@/store/refershTokenInterval'

export default {
  props: {
    closePopupEvent: {
      type: Function,
    },

    profile: {
      type: Object,
    },

    isSetNewPin: {
      type: Boolean,
    },
  },

  data() {
    return {
      width: "30%",
      margin: "15% auto",
      radiusType: "full",
      userPin: [],
      profilePin: null,
      formError: null,
      providerUniqueId: _providerId,
      numberOfOtpBlocks: 4,
      isLoading: false,
      dateOfBirth: null,
      datePlaceholder: this.$t("date of birth"),
      maxDateLimit: null,
    };
  },

  watch: {
    formError() {
      setTimeout(() => {
        this.formError = null;
      }, 2000);
    },
  },

  mounted() {
    this.setupPin();

    this.setMaxDateForDatePicker();
  },

  methods: {
    ...mapActions(["switchProfile", "updateSubscriber", "parentalControlStatus"]),

    ...mapMutations(["setProfileid"]),

    setupPin() {
      setTimeout(() => {
        this.getId = document.getElementById("pininputs");
        this.pinFunctionality(this.getId);
      }, 1000);
    },

    setMaxDateForDatePicker() {
      const dateNow = new Date();

      const yearsAgo = dateNow.getFullYear() - 18;

      const currentMonth = dateNow.getMonth() + 1;

      this.maxDateLimit = `${yearsAgo}-${currentMonth < 10 ? "0" + currentMonth : currentMonth}-${
        dateNow.getDate() < 10 ? "0" + dateNow.getDate() : dateNow.getDate()
      }`;
    },

    grabPin() {
      if (this.userPin.length > 3) {
        let pin = "";
        this.userPin.forEach((element) => {
          pin += element;
        });
        this.profilePin = pin;
      }
    },

    submitVerifyOrSetProfile() {
      this.isLoading = true;

      if (!this.profilePin || (this.profilePin && this.profilePin.length < this.numberOfOtpBlocks)) {
        this.formError = this.$t("Please enter the Pin");
        this.isLoading = false;

        return;
      }

      if (this.isSetNewPin && !this.dateOfBirth) {
        this.formError = this.$t("Please enter the Date of Birth");
        this.isLoading = false;

        return;
      }

      if (!this.isSetNewPin) {
        const profilePayload = {
          profileid: this.profile.profileid,
          pin: {
            profilepin: this.profilePin,
          },
        };

        this.switchProfile(profilePayload)
          .then((response) => {
            if (!response.data.reason) {
              this.isLoading = false;
              localStorage.setItem("sessionToken", response.data.success);
              localStorage.setItem("refreshtoken", response.data.refreshtoken);

              try {
                  actSaveExpireTime(jwt_decode(response.data.success).expiresIn)
              localStorage.setItem("refresh-token-expire",jwt_decode(response.data.success).expiresIn);
              getFreshTokenUpdate();

              this.parentalControlStatus()
                .then((data) => {
                  if (data.reason) {
                    return;
                  }
                  if (data.data.isenabled) {
                    localStorage.setItem("parentalControlEnabled", data.data.isenabled);
                  }
                });

            } catch (e) {}   
              

              localStorage.setItem("currentProfileId", profilePayload.profileid);
              localStorage.setItem("subscriberProfileDetails", JSON.stringify(this.profile));

              this.setProfileid(profilePayload.profileid);
              let currentLanguage = this.getCurrentLanguageUrlBase();
              this.$router.push({ name: "addProfile", params: { lang: currentLanguage}});

            } else {

              this.userPin = [];
              this.profilePin = "";

              // snackbar.show({
              //   text: this.$t(response.data.reason),
              //   textColor: "#ffffff",
              //   pos: "bottom-center",
              //   actionTextColor: "#de2600",
              // });

              showSnackBar(this.$t(response.data.reason));

              this.isLoading = false;
            }
          })
          .catch((err) => {
            this.isLoading = false;
          });
      } else {
        const profilePayload = {
          profileid: this.profile.profileid,
          profilepin: this.profilePin,
          dob: this.dateOfBirth,
        };

        this.updateSubscriber(profilePayload)
          .then((response) => {
            if (response.data.success) {
              this.closePopup();

              this.setLocalProfileData();
              let currentLanguage = this.getCurrentLanguageUrlBase();

              this.$router.push({ name: "addProfile", params: { lang: currentLanguage}});
            } else {
              this.userPin = [];
              this.profilePin = "";

              // snackbar.show({
              //   text: this.$t(response.data.reason),
              //   textColor: "#ffffff",
              //   pos: "bottom-center",
              //   actionTextColor: "#de2600",
              // });

              showSnackBar(this.$t(response.data.reason));
            }
          })
          .catch((error) => {
            console.log("Error in Edit Profile -> ", error);
          });
      }
    },

    setLocalProfileData() {
      let profileDetails = localStorage.getItem("subscriberProfileDetails");

      if (profileDetails) {
        profileDetails = JSON.parse(profileDetails);

        profileDetails.profilepin = "ENABLED";

        localStorage.setItem("subscriberProfileDetails", JSON.stringify(profileDetails));
      }
    },

    validateOtp(pin) {
      this.formError = null;
      if (!pin) {
        this.formError = "Please enter pin";
        return false;
      }
      return true;
    },

    closePopup() {
      console.log("CLOSE POPUP");

      this.$emit("closeProfilePinPopup");
    },
  },

  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },

  mixins: [Utility],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.profile-switch {
  padding: 3.6rem;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  &-pin-title {
    font-size: 1.4rem;
    color: $clr-saina-light-dark-txt;
    font-family: $font-regular;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
    margin-bottom: 1.2rem;
  }

  .profile-details {
    &-title {
      font-size: 1rem;
      color: $clr-saina-light-dark-txt;
      font-family: $font-regular;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 29px;
    }

    &-name {
      color: $clr-light-gd4;
      color: $clr-saina-light-dark-txt;
      font-family: $font-regular;
      letter-spacing: 0;
      line-height: 29px;
      margin-bottom: 1.2rem;
      font-size: 0.8rem;
    }

    &-dob {
      margin: 1.5rem 0;
    }
  }

  &-pin-box {
    padding-bottom: 20px;
    label {
      font-size: 0.8rem;
    }
    &-inputs {
      display: flex;
      align-items: center;
      .pin {
        width: 25%;
        margin-right: 5px;
        text-align: center;
      }
    }
  }

//   .profileDate {
//     &::-webkit-calendar-picker-indicator {
//       filter: invert(1);
//     }
//   }
// }

.profileDate {
      // height: 2rem;
      // background: rgb(219, 195, 195);

      &::-webkit-calendar-picker-indicator {
        filter: invert(1) sepia(100%) saturate(10000%) hue-rotate(180deg);
      }
    }
  }

@media only screen and (max-width: 576px) {
  .profile-switch {
    &-pin-title {
      font-size: 0.9rem;
    }
  }
}
</style>
